import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { catapult_scores_table, main_event_table } from 'configs/airtable';

// Create a context
const CatapultContext = createContext();

// Custom hook to use Catapult context
export const useCatapultContext = () => useContext(CatapultContext);

export const CatapultProvider = ({ children }) => {
  const [junior_division_scores, setJuniorDivisionScores] = useState([]);
  const [senior_division_scores, setSeniorDivisionScores] = useState([]);
  const [certificate_url, setCertificateUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    console.log('Fetching data from Airtable');
    try {
      const response = await catapult_scores_table
        .select({
          sort: [
            {
              field: 'fx_total_score',
              direction: 'desc'
            }
          ]
        })
        .all();

      const data = response.map(record => record.fields);
      setJuniorDivisionScores(
        data.filter(score => score['lookup_team_division'][0] === 'Junior')
      );

      setSeniorDivisionScores(
        data.filter(score => score['lookup_team_division'][0] === 'Senior')
      );

      const certificate_url_response = await main_event_table.find(
        'recokaqFfbEoil29y'
      );

      setCertificateUrl(
        certificate_url_response.fields.participation_certificate[0].url
      );

      setLoading(false);
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <div>Loading data...</div>;
  }

  return (
    <CatapultContext.Provider
      value={{
        junior_division_scores,
        senior_division_scores,
        certificate_url,
        loading,
        error
      }}
    >
      {children}
    </CatapultContext.Provider>
  );
};

CatapultProvider.propTypes = {
  children: PropTypes.node.isRequired
};
